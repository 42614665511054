<template>
  <div>
    <div class="forgetPsd">
      <div class="checkMethods" v-if="step == 2">
        <div class="checkMethodsCon">
          <div class="checkMethodsOpt">
            <img
              src="../../../../assets/user-img/changeImg/password.svg"
              alt=""
            />
            <span>{{ $fanyi("密码验证") }}</span>
            <button
              @click="
                approveType = 'password';
                next();
              "
            >
              {{ $fanyi("确定") }}
            </button>
          </div>
          <div class="checkMethodsOpt">
            <img src="../../../../assets/newImg/mobile.svg" alt="" />
            <span>{{ $fanyi("手机号码") }}</span>
            <button
              @click="
                approveType = 'mobile';
                next();
              "
            >
              {{ $fanyi("确定") }}
            </button>
          </div>
          <div class="checkMethodsOpt">
            <img src="../../../../assets/newImg/email.svg" alt="" />
            <span>{{ $fanyi("电子邮箱") }}</span>
            <button
              @click="
                approveType = 'email';
                next();
              "
            >
              {{ $fanyi("确定") }}
            </button>
          </div>
        </div>
      </div>
      <div class="changePasswordBox" v-if="step >= 3">
        <nav class="step">
          <div :class="step == 3 ? 'active stepOpt' : 'stepOpt'">
            <label>1</label>
            <span>{{ $fanyi("身份确认") }}</span>
          </div>
          <hr />
          <div :class="step == 4 ? 'active stepOpt' : 'stepOpt'">
            <label>2</label>
            <span
              >{{ changeType == "password" ? $fanyi("登录密码修改") : ""
              }}{{ changeType == "email" ? $fanyi("电子邮箱修改") : ""
              }}{{ changeType == "mobile" ? $fanyi("手机号修改") : "" }}</span
            >
          </div>
          <hr />
          <div :class="step == 5 ? 'active stepOpt' : 'stepOpt'">
            <label>3</label>
            <span class="gengGaiChenGong">{{ $fanyi("更改成功") }}</span>
          </div>
        </nav>
        <div
          class="identityVerification passWordReg"
          v-if="step == 3 && approveType == 'password'"
        >
          <p class="userInfo">
            <span></span>
            <button @click="qiTaRenZheng()">
              {{ $fanyi("其他认证方式") }}
            </button>
          </p>
          <div class="regInputBox password">
            <label style="width: 200px"
              ><font class="import">*</font>
              {{ $fanyi("请输入登录密码") }}：</label
            >
            <div class="numbox">
              <input
                type="password"
                v-model="oldPassword"
                autocomplete="new-password"
                :placeholder="$fanyi('请输入登录密码')"
              />
              <br />
              <span>{{ numberTishi }}</span>
            </div>
          </div>

          <button class="nextBtn" @click="passwordTypeNext()">
            {{ $fanyi("下一步") }}
          </button>
        </div>
        <div
          class="identityVerification"
          v-if="step == 3 && approveType != 'password'"
        >
          <p class="userInfo">
            <span
              >{{
                approveType == "mobile" ? $fanyi("手机号码") : $fanyi("邮箱")
              }}：{{ login_info[approveType]
              }}{{
                approveType == "mobile"
                  ? $store.state.userInfo.mobile
                  : $store.state.userInfo.email
              }}</span
            >
            <button @click="qiTaRenZheng()">
              {{ $fanyi("其他认证方式") }}
            </button>
          </p>

          <div class="regInputBox">
            <label :class="approveType == 'mobile' ? 'numBox' : ''"
              ><font class="import">*</font> {{ $fanyi("验证码") }}：</label
            >
            <div class="regCodeBox">
              <input type="text" v-model="regCode" />
              <span>{{ numberTishiTwo }}</span>
            </div>

            <button v-if="showBtn" @click.prevent="sendVerificationCode">
              {{ $fanyi("验证码获取") }}
            </button>
            <button v-else class="sixSec" @click.prevent>
              {{ Countdown }} S
            </button>
          </div>
          <button class="nextBtn" @click="next()">
            {{ $fanyi("下一步") }}
          </button>
        </div>
        <div style="height: 50px" v-if="changeType == 'email'"></div>
        <div class="newPassWord" v-if="step == 4 && changeType == 'password'">
          <el-form
            ref="formRef"
            :rules="formRules"
            :model="newPassword"
            label-width="274px"
          >
            <el-form-item :label="$fanyi('新密码') + '：'" prop="one">
              <el-input v-model="newPassword.one" show-password></el-input>
            </el-form-item>
            <el-form-item :label="$fanyi('再次确认密码') + '：'" prop="two">
              <el-input v-model="newPassword.two" show-password></el-input>
            </el-form-item>
          </el-form>

          <button class="nextBtn" @click="next()">
            {{ $fanyi("下一步") }}
          </button>
        </div>
        <div class="newemail" v-if="step == 4 && changeType != 'password'">
          <el-form
            ref="formRef"
            :rules="formRules"
            :model="newEmail"
            label-width="225px"
          >
            <el-form-item
              :label="$fanyi('国家') + '：'"
              :prop="changeType == 'email' ? 'emailone' : 'mobileone'"
              v-if="changeType == 'mobile'"
            >
              <el-select v-model="country" placeholder="">
                <el-option :label="'+34'" value="+34"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :label="
                (changeType == 'mobile' ? $fanyi('手机') : $fanyi('邮箱')) +
                  '：'
              "
              :prop="changeType == 'email' ? 'emailone' : 'mobileone'"
            >
              <el-input v-model="newEmail.email"></el-input>
            </el-form-item>
            <el-form-item :label="$fanyi('验证码') + '：'" prop="checktwo">
              <div class="checkBox">
                <el-input v-model="newEmail.regCode"></el-input>
                <button v-if="showBtn" @click.prevent="sendEmailCode">
                  {{ $fanyi("验证码获取") }}
                </button>
                <button
                  v-else
                  class="sixSec"
                  style="opacity: 0.3"
                  @click.prevent
                >
                  {{ Countdown }} S
                </button>
              </div>
            </el-form-item>
          </el-form>

          <button class="nextBtn" @click="changeEmailNext()">
            {{ $fanyi("下一步") }}
          </button>
        </div>
        <div class="ChangeSuccess" v-if="step == 5">
          <div class="ChangeSuccessOpt">
            <p class="messageBox">
              <img src="../../../../assets/newImg/omplete.svg" alt="" />
              <span v-if="$route.query.type == 'password'">{{
                $fanyi("登录密码更改成功")
              }}</span>
              <span v-else-if="$route.query.type == 'email'">{{
                $fanyi("邮箱更改成功")
              }}</span>
              <span v-else-if="$route.query.type == 'mobile'">{{
                $fanyi("手机号更改成功")
              }}</span>
            </p>
            <button class="nextBtn" @click="$fun.routerToPage('/user/index')">
              {{ $fanyi("返回主页") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$fanyi("请再次输入密码")));
      } else if (value !== this.newPassword.one) {
        callback(new Error(this.$fanyi("两次输入密码不一致!")));
      } else {
        callback();
      }
    };
    return {
      step: 2,
      changeType: this.$route.query.type,
      login_name: this.$store.state.userInfo.login_name, //用户名
      login_info: "abner1", //用户账户信息
      approveType: "password", //用户验证身份的方式
      showBtn: true, //发送验证码按钮控制
      Countdown: 60, //秒数
      regCode: "", //验证码
      regNext: false,
      oldPassword: "",
      country: "+34", //手机号区号
      onumber: "", //绑定的邮箱或手机号
      tiShi: "",
      numberTishiTwo: "",
      numberTishi: "",
      newPassword: {
        one: "",
        two: "",
      },
      newEmail: {
        email: "",
        regCode: "",
      },
      formRules: {
        one: [
          {
            required: true,
            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,16}$/,
            message: this.$fanyi("请输入6-16位包含大小写字母和数字的字符串"),
          },
        ],
        two: [
          {
            required: true,
            validator: validatePass2,
          },
        ],
        checktwo: [],
        mobileone: [],
        emailone: [],
      },
    };
  },
  watch: {
    $route: {
      handler(route) {
        this.step = 2;
        this.changeType = this.$route.query.type;
        this.$forceUpdate();
      },
    },
  },
  components: {},
  computed: {},
  created() {},
  methods: {
    passwordTypeNext() {
      this.$api
        .userCheckPassword({
          password: this.oldPassword,
        })
        .then((res) => {
          //console.log("userCheckPassword", res);
          if (res.data != true) {
            this.regNext = false;
            return this.$message.error(this.$fanyi("密码错误"));
          }
          this.step++;
        });
    },

    // 更改用户信息并进入下一步
    changeEmailNext() {
      if (!this.newEmail.email || !this.newEmail.regCode) {
        return this.$message(this.$fanyi("必填参数缺失"));
      }
      var emailRegExp = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (
        this.changeType == "email" &&
        !emailRegExp.test(this.newEmail.email)
      ) {
        return this.$message(this.$fanyi("请输入正确的邮箱地址"));
      }
      this.checkVerificationCode(() => {
        if (this.changeType == "mobile") {
          this.$api
            .userMobileSave({
              mobile: this.newEmail.email,
            })
            .then((res) => {
              //console.log("userEmailSave", res);
              if (res.code != 0)
                return this.$message.error(this.$fanyi(res.msg));
              this.step++;
            });
        }
        if (this.changeType == "email") {
          this.$api
            .userEmailSave({
              email: this.newEmail.email,
            })
            .then((res) => {
              //console.log("userEmailSave", res);
              if (res.code != 0)
                return this.$message.error(this.$fanyi("ya está en uso"));
              this.step++;
            });
        }
      }, "changeEmail");
    },
    // 设置新密码
    changePassword() {
      let datas = {
        password: this.newPassword.one,
        login_name: this.login_name,
        verification: {
          number: this.onumber,
        },
      };
      this.$api.userPasswordSave(datas).then((res) => {
        if (res.success == true) {
          this.step++;
        } else {
          this.$message.error(this.$fanyi("操作失败"));
        }
      });
    },
    // 验证验证码
    checkVerificationCode(fn, type) {
      let datas = {
        occasion: "forget",
        number:
          this.approveType == "email" ? this.onumber : this.onumber.substr(3),
        code: this.regCode,
      };
      if (type == "changeEmail") {
        datas.occasion = "auto";
        datas.number = this.newEmail.email;
        datas.code = this.newEmail.regCode;
        if (this.changeType == "mobile") {
          datas.area_code = this.country;
        }
      }

      if (!datas.number) {
        return this.$message(this.$fanyi("重要参数为空"));
      }
      this.$api.checkVerificationCode(datas).then((res) => {
        if (res.success == true) {
          fn();
        } else {
          this.$message.warning(this.$fanyi("验证码错误"));
          return false;
        }
      });
    },
    // 发送邮箱验证码
    sendEmailCode() {
      if (!this.newEmail.email) {
        return this.$message(this.$fanyi("必填参数缺失"));
      }
      var emailRegExp = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (
        this.changeType == "email" &&
        !emailRegExp.test(this.newEmail.email)
      ) {
        return this.$message(this.$fanyi("请输入正确的邮箱地址"));
      }

      // let mobileReg = /^(\w){9}$/;
      // if (this.changeType == "mobile" && !mobileReg.test(this.newEmail.email)) {
      //   return this.$message(this.$fanyi("手机号输入错误"));
      // }
      let datas = {
        occasion: "auto",
        tool: this.changeType,
        number: this.newEmail.email,
        login_name: this.login_name,
      };
      if (this.changeType == "mobile") {
        datas.area_code = this.country;
      }
      this.showBtn = false;
      this.Countdown = 60;
      this.$api.sendVerificationCode(datas).then((res) => {
        this.showBtn = true;
        if (res.code != 0) return this.$message.error(this.$fanyi("操作失败"));
        this.showBtn = false;
        this.numberTishi = "";
        this.Countdown = 60;
        var timer = setInterval(() => {
          this.Countdown--;
          if (this.Countdown <= 0) {
            this.showBtn = true;
            this.Countdown = 60;
            clearInterval(timer);
          }
        }, 1000);
      });
    },
    qiTaRenZheng() {
      this.step = 2;
      this.numberTishiTwo = "";
      this.numberTishi = "";
      this.onumber = "";
      this.oldPassword = "";
      this.regCode = "";
      this.tiShi = "";
      this.$forceUpdate();
    }, // 发送验证码
    sendVerificationCode() {
      let datas = {
        occasion: "forget",
        tool: this.approveType,
        number:
          this.approveType == "email" ? this.onumber : this.onumber.substr(3),

        login_name: this.login_name,
      };
      if (this.approveType == "mobile") {
        datas.area_code = this.country;
      }
      this.showBtn = false;
      this.Countdown = 60;
      this.$api.sendVerificationCode(datas).then((res) => {
        this.showBtn = true;
        if (res.code != 0) return this.$message.error(this.$fanyi("操作失败"));
        this.showBtn = false;
        this.numberTishi = "";
        var timer = setInterval(() => {
          this.Countdown--;
          if (this.Countdown <= 0) {
            this.showBtn = true;
            this.Countdown = 60;
            clearInterval(timer);
          }
        }, 1000);
      });
    },
    next() {
      switch (this.step) {
        case 2:
          this.onumber =
            this.approveType == "mobile"
              ? this.$store.state.userInfo.mobile
              : this.$store.state.userInfo.email;

          this.step++;
          break;
        case 3:
          this.checkVerificationCode(() => {
            this.Countdown = 0;
            this.step++;
          });
          break;
        case 4:
          this.$refs.formRef.validate((valid) => {
            if (valid) {
              this.changePassword();
            }
          });
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "../../../../css/mixin.scss";
.forgetPsd {
  background: #fafafa;
  > div {
    width: 100%;
    margin: 0 auto;
    height: 442px;
    background: #ffffff;
    border: 1px solid #e1e1e1;
  }

  // 确认认证方式
  .checkMethods {
    position: relative;
    .checkMethodsCon {
      width: 450px;
      margin: 0 auto;
      padding-top: 124px;
      h1 {
        position: absolute;
        font-size: 18px;

        color: #000000;
        line-height: 24px;

        top: 20px;
        left: 20px;
      }
      .checkMethodsOpt {
        height: 64px;
        background: #ffffff;
        border-radius: 2px;
        border: 1px solid #e1e1e1;
        display: flex;
        align-items: center;
        padding-left: 23px;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        img {
          width: 24px;
          margin-right: 13px;
        }
        span {
          font-size: 14px;

          color: #000000;
          line-height: 19px;
          margin-right: auto;
        }
        button {
          width: 96px;
          height: 40px;
          margin-right: 10px;
          background: #1e2997;
          border-radius: 4px;
          font-size: 16px;

          color: #ffffff;
          line-height: 18px;
        }
      }
    }
  }
  // 修改密码
  .changePasswordBox {
    padding-top: 80px;
    .step {
      margin: 0 auto 40px;
      width: 915px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      hr {
        flex: 1;
        height: 1px;
        background: #e1e1e1;
        border: none;
        margin: 0 20px;
      }
      .stepOpt {
        &.active {
          label {
            color: #ffffff;
            background: #1e2997;
          }
          span {
            color: #1e2997;
          }
        }
        display: flex;
        align-items: center;
        label {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          color: #888888;
          border: 1px solid #888888;
          border-radius: 50%;
          margin-right: 12px;
        }
        span {
          font-size: 16px;

          color: #888888;
          line-height: 16px;

          max-width: 200px;
          text-align: center;
          // &.gengGaiChenGong {
          //   width: 120px;
          //   text-align: center;
          // }
        }
      }
    }
    > div {
      width: 715px;
      margin: 0 auto;
    }
    // 身份验证
    .identityVerification {
      // 密码验证
      &.passWordReg {
        .password {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      // **********************************************************************************************************

      .userInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 43px;
        span {
          font-size: 18px;

          color: #000000;
          line-height: 24px;
        }
        button {
          font-size: 16px;

          color: $homePageSubjectColor;
          line-height: 18px;

          background-color: transparent;
          border: none;
          outline: none;
        }
      }
      .regInputBox {
        margin-bottom: 20px;
        display: flex;
        align-items: flex-start;
        &:last-child {
          mask-border: 40px;
        }
        .numBox {
          width: 200px;
        }
        .regCodeBox {
          input {
            margin-bottom: 3px;
          }
          span {
            display: block;
            font-size: 14px;

            color: #ff0000;
            line-height: 20px;
          }
        }
        > label {
          display: inline-block;
          width: 200px;
          height: 20px;
          font-size: 14px;

          color: #000000;
          line-height: 20px;
          text-align: right;
          margin-right: 10px;
        }

        /deep/.el-input {
          width: 90px; //组件宽
          height: 26px; //组件高
          margin-right: 10px;
        }
        /deep/.el-input__inner {
          //定义组件内容区域
          font-size: 12px;

          background-color: #f6f6f6;
          color: #000000;
          line-height: 16px;
          border-radius: 6px;
          height: 100%;
          border: 1px solid rgba($color: $homePageSubjectColor, $alpha: 0.3);
        }
        /deep/.el-select__caret {
          //组件内容小图标居中
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .numbox {
          display: inline-block;
          span {
            font-size: 14px;

            color: #ff0000;
            line-height: 20px;
          }
        }
        input {
          width: 240px;
          height: 26px;
          background: #f6f6f6;
          border-radius: 5px;
          border: 1px solid #d7d7d7;
          margin-right: 10px;
          padding-left: 10px;
        }
        > span {
          font-size: 14px;

          color: #ff0000;
          line-height: 20px;
        }
        > button {
          min-height: 26px;
          padding: 0 12px;
          background: #1e2997;
          border-radius: 5px;
          font-size: 14px;

          color: #ffffff;
          line-height: 20px;
          &.sixSec {
            max-width: 250px;
            background: #1e2997;
            border-radius: 5px;
            opacity: 0.3;
          }
        }
      }
    }
    // 输入新密码
    .newPassWord {
      margin-top: 83px;
      /deep/.el-input {
        width: 240px;
        height: 26px;
      }
      /deep/.el-input__inner {
        width: 240px;
        height: 26px;
        background: #f6f6f6;
        border-radius: 5px;
        border: 1px solid #d7d7d7;
      }
    }
    .newemail {
      .el-form {
        margin-bottom: 22px;
      }
      .el-input {
        width: 240px;
        margin-right: 10px;
      }
      /deep/.el-input__inner {
        width: 240px;
        height: 26px;
        background: #f6f6f6;
        border-radius: 5px;
        border: 1px solid #d7d7d7;
      }
      .el-form-item {
        margin-bottom: 5px;
      }
      .checkBox {
        display: flex;
        align-items: center;
        button {
          padding: 3px 12px;
          background: #1e2997;
          border-radius: 5px;
          line-height: 16px;

          font-size: 13px;
          color: #ffffff;
        }
      }
    }
    // 修改成功
    .ChangeSuccess {
      .ChangeSuccessOpt {
        .messageBox {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 80px;
          margin-bottom: 40px;
          img {
            width: 40px;
            height: 40px;
            margin-right: 10px;
          }
          span {
            font-size: 14px;

            color: #000000;
            line-height: 19px;
          }
        }
      }
    }
  }
}
// 星号
.import {
  font-size: 14px;

  color: #ff0000;
  line-height: 20px;
}
//第三部开始的下一步按钮
.nextBtn {
  display: block;
  margin: 0 auto;
  padding: 0 10px;
  height: 40px;
  background: #1e2997;
  border-radius: 4px;
  font-size: 16px;

  color: #ffffff;
  line-height: 18px;
}
/deep/.el-form-item__error {
  top: 30px;
}
</style>
